<template>
  <div>
    <div class="contents">
        <div class="col-lg-12">
            <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Subjects
                    <ul class="atbd-breadcrumb nav">
                        <li class="atbd-breadcrumb__item">
                            <a href="#">
                                <span class="la la-home"></span>
                            </a>
                            <span class="breadcrumb__seperator">
                                <span class="la la-slash"></span>
                            </span>
                        </li>
                        <li class="atbd-breadcrumb__item">
                            <a href="#">Metrics</a>
                            <span class="breadcrumb__seperator">
                                <span class="la la-slash"></span>
                            </span>
                        </li>
                        <li class="atbd-breadcrumb__item">
                            <span>Subjects</span>
                        </li>
                    </ul>
                </h4>

            </div>
        </div>
      <div class="container-fluid">
        <div class="social-dash-wrap">
          <div class="row">
            <!-- <div class="col-lg-12">
              <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Subjects</h4>
              </div>
            </div> -->

            <!---  Dashboard Cards -->

            <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
              <router-link to="/stat/classes">

              <div
                class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos"
              >
                <div>
                  <div class="overview-content">
                    <h1>{{ classes.length || 0}}</h1>
                    <p>{{ classes.length > 1 ? "Classes" : "Class" }}</p>
                  </div>
                </div>
                <div class="side-icon2">
                  <img src="@/assets/img/custom/classes.gif" width="80" />
                </div>
              </div>
              </router-link>
            </div>
            <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
              <div
                class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos"
              >
                <div>
                  <div class="overview-content">
                    <h1>{{ subjects.length || 0}}</h1>
                    <p>{{ subjects.length > 1 ? "Subjects" : "Subject" }}</p>
                  </div>
                </div>
                <div class="side-icon2">
                  <img src="@/assets/img/custom/books.gif" width="80" />
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-xxl-6 col-ssm-12 mb-30">
              <div
                class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos"
              >
                <div>
                  <div class="overview-content">
                    <h1>{{ stat.topics || 0 }}</h1>
                    <p>{{ stat > 1 ? "Topics" : "Topic" }}</p>
                  </div>
                </div>
                <div class="side-icon2">
                  <img src="@/assets/img/custom/topic.gif" width="80" />
                </div>
              </div>
            </div>

            <!--  Dashboard Cards end -->

            <div class="col-lg-12 mb-25 col-xxl">
              <div class="card border-0">
                <div class="card-header">
                  <h6>Classes</h6>
                  <div class="card-extra">
                    <div class="card-tab btn-group atbd-button-group mr-3 nav nav-tabs">
                      <a
                        v-if="primary.length > 0"
                        class="btn btn-xs btn-white border active"
                        id="f_today-tab"
                        data-toggle="tab"
                        href="#st_matrics-today"
                        role="tab"
                        area-controls="st_matrics"
                        aria-selected="false"
                        @click="activeTab = 'primary'"
                        >Primary</a
                      >
                      <a
                        v-if="junior.length > 0"
                        class="btn btn-xs btn-white border"
                        id="f_week-tab"
                        data-toggle="tab"
                        href="#st_matrics-week"
                        role="tab"
                        area-controls="st_matrics"
                        aria-selected="true"
                        @click="activeTab = 'junior'"
                        >Junior Secondary</a
                      >
                      <a
                        v-if="senior.length > 0"
                        class="btn btn-xs btn-white border"
                        id="f_month-tab"
                        data-toggle="tab"
                        href="#st_matrics-month"
                        role="tab"
                        area-controls="st_matrics"
                        aria-selected="false"
                        @click="activeTab = 'senior'"
                        >Senior Secondary</a
                      >
                    </div>
                  </div>
                </div>

                <div class="card-body p-0">
                  <div class="tab-content">
                    <div
                      class="tab-pan fade active show"
                      id="st_matrics-today"
                      role=""
                      aria-labelledby="st_matrics-tab"
                    >
                      <div class="table-responsive">
                        <div
                          v-if="activeClass.length < 1"
                          class="no-content text-center"
                        >
                          <img
                            src="@/assets/img/custom/puppy-no-content.png"
                            width="200"
                          />
                          <br />
                          <h3>Oops</h3>
                          <h6>No Content here...</h6>
                        </div>
                        <div v-else class="table-responsive">
                          <table class="table table-bordered table-social">
                            <thead>
                              <tr>
                                <th>Subjects</th>
                                <th class="text-right">Class</th>
                                <th class="text-right">Total Questions</th>
                                <th class="text-right">Total Topics</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(sub, j) in activeClass" :key="j" class="pointer" @click="gotoClassTopics({name: 'classes-topics', params: { sid: sub.sulid, sname: sub.name, cname: sub.name, clsId: sub.cllid, url: '/classes/junior' }})">
                              <td>
                                <span>{{ sub.name }}</span>
                              </td>
                              <td>
                                <span>{{ classMap[sub.cllid].nick }}</span>
                              </td>
                              <td>
                                <span>{{ sub.total_question }}</span>
                              </td>
                              <td>
                                <span>{{ sub.total_topic }}</span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SpinOverlay :active="isLoading && !asyncLoader" />
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay-dark-sidebar"></div>
    <div class="customizer-overlay"></div>
    <!-- ends: .atbd-page-content -->
    <div
      class="modal-basic modal fade show"
      id="modal-basic"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md modal-info" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-info-body d-flex">
              <div class="modal-info-text text-center">
                <h4>✌🏽 Hi <span class="apptxt"> Jadesola Chukwuma </span></h4>
                <div class="text-center">
                  <img src="@/assets/img/custom/new-book.gif" width="200" />
                </div>
                <br />
                <h6>
                  Here are some verification you need to fulfill to enable you
                  have access to the amazing features
                </h6>
                <p>Some descriptions</p>
                <div class="row btn-auto">
                  <button
                    type="button"
                    class="btn btn-light btn-outlined btn-sm"
                    data-dismiss="modal"
                  >
                    I am not ready
                  </button>
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    onclick="window.location.href='verifyme.html';"
                    data-dismiss="modal"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
            <br />
          </div>
          <!--<div class="modal-footer">
                <button type="button" class="btn btn-light btn-outlined btn-sm" data-dismiss="modal">I am not ready</button>
                <button type="button" class="btn btn-info btn-sm" data-dismiss="modal">Ok</button>
            </div>-->
        </div>
      </div>
    </div>
    <!-- ends: .modal-Basic -->

    <div
      class="modal-info-confirmed modal fade show"
      id="modal-info-confirmed"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-info" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-info-body d-flex">
              <div class="modal-info-icon warning">
                <span data-feather="info"></span>
              </div>
              <div class="modal-info-text">
                <h6>Do you Want to delete these items?</h6>
                <p>Some descriptions</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light btn-outlined btn-sm"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-info btn-sm"
              data-dismiss="modal"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers.js";
import { apiClient } from "@/plugins/restclient.js";
import SpinOverlay from "@/components/SpinOverlay";
import { mapState } from "vuex";
export default {
  name: "home",
  components: { SpinOverlay },
  data() {
    return {
      questionReview: {},
      isLoading: false,
      activeTab:"primary",
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Dashboard",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapState([
      "asyncLoader",
      "primary",
      "junior",
      "senior",
      "primarySub",
      "juniorSub",
      "seniorSub",
      "classes",
      "classMap",
      "topics",
      "subjects",
      "stat",
    ]),
    ...authComputed,
    activeClass(){
      if(this.activeTab == 'primary'){
        return  this.primarySub
        }else if(this.activeTab == 'junior'){
          return this.juniorSub
      }else{
        return this.seniorSub
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem("jwt")) {
      next("/login");
    }
    next();
  },
  beforeMount() {
    if (this.$route.params.login) {
      // location.reload()
      this.$router.go();
    }
  },
  mounted() {
    this.getQuestions();
    // this.getStudents();
  },
  methods: {
    gotoClassTopics(route){
      this.$router.push(route)
    },
    getQuestions() {
      var ts = Math.round(+new Date() / 1000);

      const headers = {
        "X-Ulid": `${this.$store.state.user.ulid}`,
        "X-Timestamp": ts,
      };

      apiClient
        .get("/v1/office/assessment/question/stat", {
          headers: headers,
        })
        .then((res) => {
          this.questionReview = res.data.info.review;
        })
        .catch(() => {});
    },
    getStudents() {
      var ts = Math.round(+new Date() / 1000);
      const headers = {
        'X-Ulid': `${this.$store.state.user.ulid}`,
        "X-Timestamp": ts,
      };
      apiClient.get('/v1/office/operation/users/fetch/role/1', {
        headers: headers,
          }).then(() => {
        }).catch(() => {
        });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
